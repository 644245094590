import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Normalize } from 'styled-normalize';
import ReactGA from 'react-ga';
import GlobalStyles from '../styles/GlobalStyles';
import Landing from './Landing/Landing';
import HeaderNav from './HeaderNav';

const Genes = lazy(() => import('./SummaryPages/Genes'));
const Compounds = lazy(() => import('./SummaryPages/Compounds'));
const Datasets = lazy(() => import('./SummaryPages/Datasets'));
const Cells = lazy(() => import('./SummaryPages/Cells'));
const Tissues = lazy(() => import('./SummaryPages/Tissues'));
const Species = lazy(() => import('./SummaryPages/Species'));
const Pathways = lazy(() => import('./SummaryPages/Pathways'));
const CompoundCard = lazy(() => import('./IndivPages/CompoundPage'));
const GeneCard = lazy(() => import('./IndivPages/GenePage'));
const DatasetCard = lazy(() => import('./IndivPages/DatasetPage'));
const Expression = lazy(() => import('./IndivPages/Expression'));
const SynonymGene = lazy(() => import('./IndivPages/SynonymGene'));
const Documentation = lazy(() => import('./Documentation/MainDocument'));
const BarChart = lazy(() => import('./Plots/BarChat'));
const Contact = lazy(() => import('./Contact'));

const StyledApp = styled.div`
    margin: 20px auto 0px auto;
    min-height:100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;    
    flex-grow: 1;
    font-family: 'Raleway', sans-serif;
    order:2;
    z-index:1;
`;

const App = () => {
    // Google analytics setup.
    useEffect(() => {
        ReactGA.initialize('UA-102362625-4');
        // To Report Page View
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div>
            <Normalize />
            <GlobalStyles />
            <StyledApp className="app">
                <Switch>
                    <Suspense fallback={<div />}>
                        <Route exact path="/" component={Landing} />
                        <Route exact path="/genes/" component={Genes} />
                        <Route exact path="/compounds/" component={Compounds} />
                        <Route exact path="/datasets/" component={Datasets} />
                        <Route exact path="/cells/" component={Cells} />
                        <Route exact path="/species/" component={Species} />
                        <Route exact path="/tissues/" component={Tissues} />
                        <Route exact path="/pathways" component={Pathways} />
                        <Route exact path="/contact/" component={Contact} />
                        <Route exact path="/documentation/" component={Documentation} />
                        <Route path="/compounds/:id" component={CompoundCard} />
                        <Route path="/genes/:id" component={GeneCard} />
                        <Route path="/datasets/:id" component={DatasetCard} />
                        <Route path="/expression" component={Expression} />
                        <Route path="/bar" component={BarChart} />
                        <Route path="/synonym" component={SynonymGene} />
                    </Suspense>
                </Switch>
            </StyledApp>
            <HeaderNav />
        </div>
    );
};

export default App;
