/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../styles/colors';
import MenuList from './MenuList';

const customStyles = {
    control: (provided) => ({
        ...provided,
        background: colors.lightblue_bg,
        borderRadius: '35px',
        height: 80,
        fontFamily: '\'Raleway\', sans-serif',
        fontSize: 'calc(0.5em + 0.8vw)',
        fontWeight: 600,
        color: colors.blue_header,
        marginTop: '80px',
        padding: '0 20px',
        border: 'none',
        '&:hover': {
            cursor: 'text',
        },
        '&:focus': {
            outline: 'none',
            border: 'none',
            boxShadow: 'none',
        },
    }),
    input: (provided) => ({
        ...provided,
        padding: '0 0px',
        color: colors.blue_header,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: `${colors.blue_header}`,
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: `${colors.blue_header}`,
        '&:hover': {
            color: `${colors.blue_header}`,
            cursor: 'pointer',
        },
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: `${colors.blue_header}`,
        '&:hover': {
            color: `${colors.blue_header}`,
            cursor: 'pointer',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        background: `${colors.blue_header}`,
        '&:hover': {
            background: `${colors.blue_header}`,
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: `${colors.blue_header}`,
    }),
    multiValue: (provided) => ({
        ...provided,
        color: `${colors.blue_header}`,
        background: '#fff',
        marginRight: '10px',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: `${colors.blue_header}`,
    }),
    option: (provided) => ({
        ...provided,
        textAlign: 'left',
        fontWeight: '400',
        background: 'white',
        color: colors.blue_header,
    }),
};

const CustomOption = (innerProps) => (
    <components.Option {...innerProps}>
        <div
            style={{
                backgroundColor: innerProps.isFocused ? colors.lightblue_bg : 'inherit',
                height: 40,
                padding: '13px 20px',
                '&:hover': {
                    background: colors.lightblue_bg,
                },
            }}
        >
            <span>{innerProps.label}</span>
        </div>
    </components.Option>
);

const customFilterOption = (option, rawInput) => {
    const words = rawInput.split(' ');
    return words.reduce(
        (acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
        true,
    );
};

const StyledExample = styled.div`
    margin: 20px 0 0 10px;
    color: ${colors.blue_text};

    a {
        color: ${colors.red_highlight}
    }
`;

// checks the selected data to determine if user should be redirected to the gene or synonym page
const checkSynonym = (idGeneMap, selected) => {
    let output;
    if (selected.length > 0) {
        selected.forEach((selectedOption) => {
            // only check data if gene-synonym was selected
            const { type, value, label } = selectedOption;
            if (type === 'gene-synonym') {
                const listOfGenes = [...new Set(value.map((id) => idGeneMap[id].toUpperCase()))];
                if (listOfGenes.length > 1) output = label;
            }
        });
    }
    return output || null;
};

class Search extends Component {
    constructor() {
        super();
        this.state = {
            searchData: [],
            options: [],
            selected: [],
            placeholder: 'Enter a compound, gene, or gene-compound pair...',
            menuOpen: false,
            idGeneMap: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
    }

    async componentDidMount() {
        this.mounted = true;
        let searchData = [];
        const getCompounds = fetch('/api/v1/compounds').then((response) => response.json());
        const getGenes = fetch('/api/v1/genes').then((response) => response.json());
        const getCompoundSynonyms = fetch('api/v1/compounds/synonyms').then((response) => response.json());
        const getGeneSynonyms = fetch('api/v1/genes/synonyms').then((response) => response.json());
        // fetching the data from all the APIs.
        const data = await Promise.all([
            getCompounds, getGenes, getCompoundSynonyms, getGeneSynonyms,
        ]);
        // Todo: there is repetition of the code, we can improve by making functions.
        // compound data.
        const compoundData = data[0].data.map((x) => ({
            label: x.name.charAt(0).toUpperCase() + x.name.slice(1),
            value: x.id,
            type: 'compound',
        }));
        // adding compound data to searchData compound options
        searchData = searchData.concat(compoundData);

        // compound synonyms.
        const compoundSynonyms = data[2].data.map((x) => ({
            label: x.synonym.charAt(0).toUpperCase() + x.synonym.slice(1),
            value: x.compound_id,
            type: 'compound',
        }));
        searchData = searchData.concat(compoundSynonyms);

        // used to link synonym ids to rat/human genes
        const idGeneMap = {};
        data[1].data.forEach((item) => {
            const { symbol, id } = item;
            idGeneMap[id] = symbol;
        });
        // Generating an array of options fro react select where value section
        // can store multiple ids
        const geneObj = {};
        data[1].data.forEach((item) => {
            const name = item.symbol.toUpperCase();
            if (!geneObj[name]) {
                geneObj[name] = { label: name, value: [item.id] };
            } else {
                geneObj[name].value.push(item.id);
            }
        });
        const geneData = Object.values(geneObj).map((x) => ({
            label: x.label,
            value: x.value,
            type: 'gene',
        }));
        // adding genes data to searchData compound options
        searchData = searchData.concat(geneData);

        // gene synonyms.
        const geneSynonymObj = {};
        data[3].data.forEach((item) => {
            if (item.synonym !== '-') {
                // eslint-disable-next-line camelcase
                const { synonym, gene_id } = item;
                const synonymName = synonym.toUpperCase();
                // non-synonym name of the gene
                const mainGeneName = idGeneMap[gene_id].toUpperCase();
                if (!geneSynonymObj[synonymName]) {
                    // adds both rat and human gene ids to the value field
                    geneSynonymObj[synonymName] = {
                        label: synonymName, value: geneObj[mainGeneName].value,
                    };
                } else {
                    // eliminates duplicated gene id values with Set
                    geneSynonymObj[synonymName].value = [...new Set(
                        [...geneSynonymObj[synonymName].value, ...geneObj[mainGeneName].value],
                    )];
                }
            }
        });

        const geneSynonymData = Object.values(geneSynonymObj).map((x) => ({
            label: x.label,
            value: x.value,
            type: 'gene-synonym',
        }));
        searchData = searchData.concat(geneSynonymData);

        if (this.mounted) {
            // having a separate options so i can filter out options
            // but keep all the data in searchData so I don't have to make fetch statements
            this.setState({ searchData, options: searchData, idGeneMap });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {
        const { searchData } = this.state;
        // no options selected (back to none when crossing out values)
        if (event === null || event.length === 0) {
            this.setState({ options: searchData });
        } else {
            // set number of options selected
            this.setState({ selected: event });
            // filter based on the opposite of the selected value's type
            if (event.length === 1) {
                const newData = [];
                searchData.forEach((x) => {
                    if (x.type !== event[0].type) {
                        newData.push(x);
                    }
                });

                this.setState({ options: newData });
            } else if (event.length === 2) {
                // clear
                this.setState({ options: [] });
            }
        }
    }

    handleKeyDown(event) {
        const { selected, menuOpen, idGeneMap } = this.state;
        const { history } = this.props;
        if (!menuOpen) {
            let queryParams = '/';
            if (event.key === 'Enter') {
                // decides if user should be redirected to gene page or confirm the gene name if
                // gene synonym that links to multiple genes was selected
                const specialSynonym = checkSynonym(idGeneMap, selected);
                switch (selected.length) {
                // none selected, error, change placeholder
                case 0:
                    this.setState({ placeholder: 'Please select an option.' });
                    break;
                // one selected, go to indiv page
                case 1:
                    if (specialSynonym) {
                        queryParams = queryParams.concat(`synonym?name=${specialSynonym}&geneId=${selected[0].value}`);
                    } else if (selected[0].type === 'compound') {
                        queryParams = queryParams.concat(`compounds/${selected[0].value}`);
                    } else {
                        queryParams = queryParams.concat(`genes/${selected[0].value}`);
                    }
                    break;
                // two selected, then it's a gene compound pair
                case 2:
                    // decides whether query should lead to expression plot or a gene name selection
                    queryParams = specialSynonym
                        ? queryParams.concat(`synonym?name=${specialSynonym}&`)
                        : queryParams.concat('expression?');
                    if (selected[0].type === 'compound') {
                        queryParams = queryParams.concat(`compoundName=${selected[0].label}&compoundId=${selected[0].value}&geneId=${selected[1].value}`);
                    } else {
                        queryParams = queryParams.concat(`compoundName=${selected[1].label}&compoundId=${selected[1].value}&geneId=${selected[0].value}`);
                    }
                    break;
                default:
                    break;
                }
            }
            history.push(queryParams);
        }
        return null;
    }

    handleMenuOpen() {
        this.setState({ menuOpen: true });
    }

    handleMenuClose() {
        this.setState({ menuOpen: false });
    }

    render() {
        const {
            options, placeholder,
        } = this.state;
        const {
            handleChange, handleKeyDown, handleMenuOpen, handleMenuClose,
        } = this;

        return (
            <>
                <Select
                    isMulti
                    filterOption={customFilterOption}
                    options={options}
                    components={{
                        MenuList: (props) => (<MenuList {...props} />),
                        Option: CustomOption,
                    }}
                    placeholder={placeholder}
                    styles={customStyles}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                />
                <StyledExample>
                    Example Queries: &nbsp;&nbsp;
                    <Link to="/compounds/9">Acetaminophen</Link>
                    {' '}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {' '}
                    <Link to="/genes/7602,19549">CYP1A1</Link>
                    {' '}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {' '}
                    <Link to="/expression?compoundId=37&geneId=7602,19549">CYP1A1 - carbon tetrachloride</Link>
                </StyledExample>
            </>


        );
    }
}

export default withRouter(Search);
