import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from '../../styles/colors';
import transitions from '../../styles/transitions';

import Search from './Search';
import About from './About';
import FrequentQueries from './FrequentQueries';
import Collaboration from './Collaboration';

const StyledLanding = styled.div`
    width: 80vw;
    h1 {
        color: ${colors.red_highlight};
        font-family: 'Raleway', sans-serif;
        font-size: calc(1em + 1vw);

        &.title {
            text-align:center;
        }
    }
    
    .new-release {
        margin: 20px 0;
        text-align: center;

        h2 {
            color: ${colors.blue_header}
            animation: glow 2s ease-in-out infinite alternate;
            margin-bottom: 0;
        }
        p {
            font-family: Nunito Sans,sans-serif;
            margin: 5px 0 0;
            color: ${colors.blue_text};
        }
        a {
            font-family: Nunito Sans,sans-serif;
            color: ${colors.blue_header};
            transition: ${transitions.main_trans}
            
            &:hover {
                color: ${colors.red_highlight};
            }
        }
    }

    @keyframes glow {
    from {
      text-shadow: 0 0 5px ${colors.lightred_bg};
    }
    to {
      text-shadow: 0 0 7px ${colors.red_highlight}, 0 0 3px ${colors.red_highlight};
    }
  }
    
`;

const Landing = () => (
    <StyledLanding>
        {/* <div style={{ marginTop: '150px' }} className="new-release">
            <h2><Link to="/documentation?section=roadmap-dec-20">New Release</Link></h2>
        </div> */}
        <h1 style={{ marginTop: '150px' }}  className="title">Mine multiple toxicogenomic datasets.</h1>
        <Search />

        <h1 style={{ marginTop: '90px', marginBottom: '10px' }}>About our database</h1>
        <About />

        <h1 style={{ marginTop: '50px' }}>Most frequent queries</h1>
        <FrequentQueries />

        <Collaboration />
    </StyledLanding>
);

export default Landing;
